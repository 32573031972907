import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import { AuthProvider } from "../../../components/Auth/auth";
import SimpleSpring from "../../../components/Transitions/transitions";
import SignUpHeader from "../../../components/PageComponents/Signup/internal/SignUpHeader";
import styles from "../../../styles/Signin.module.scss";
import { LocationTypes } from "../../../components/Onboarding/ConnectBidddy";
import LoginLayout from "../../../components/LoginLayout";
import ReadyToRoll from "../../../components/Onboarding/ReadyToRoll";
import {
  getAuctionModalIsEditing,
  getAuction,
} from "../../../store/auctionModal/selectors";
import { sendToast } from "../../../utils/helpers";
import { Auction } from "../../../types/Auction";
import { MethodTypes } from "../../../enums/MethodTypes";
import { selectAvailableMarkets } from "../../../store/user/selectors";

const ReadyToRollPage = ({ location }) => {
  const isEditing = useSelector((state) => getAuctionModalIsEditing(state));
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const locationPath =
    location.state && location.state.location ? location.state.location : null;
  const navigationPath =
    location.state && location.state.navigationPath
      ? location.state.navigationPath
      : null;
  const accountType =
    location.state && location.state.accountType
      ? location.state.accountType
      : null;
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  );
  // handle close
  const handleClose = () => {
    if (availableMarkets.length === 0) {
      if (locationPath && locationPath === "NEW_AUTH_FLOW_CONNECTING_BIDDDY") {
        navigate("/dashboard/");
        return;
      }
      if (locationPath && locationPath === LocationTypes.Account) {
        navigate("/dashboard/account/");
        return;
      }
      let path = "create";
      if (isEditing) {
        path = "edit";
      }
      if (auction.type === MethodTypes.schedule) {
        navigate(`/dashboard/auctions/${path}/preview/`);
      } else {
        navigate(`/dashboard/auctions/${path}/add-settings/`);
      }
    } else {
      if (locationPath && locationPath === LocationTypes.Account) {
        navigate("/dashboard/account/");
        return;
      }
      if (isEditing) {
        navigate("/dashboard/auctions/edit/choose-account/", {
          state: {
            navigationPath,
            location: locationPath,
            accountType,
          },
        });
      } else {
        navigate("/dashboard/auctions/create/choose-account/", {
          state: {
            navigationPath,
            location: locationPath,
            accountType,
          },
        });
      }
    }
  };

  const handleBack = () => {
    if (locationPath && locationPath === LocationTypes.Account) {
      navigate("/dashboard/account/");
    } else {
      navigate("/dashboard/getting-started/connect-instagram/", {
        state: {
          navigationPath,
          location: locationPath,
          accountType,
        },
      });
    }
  };

  useEffect(() => {
    if (location.state && location.state.error) {
      sendToast(location.state.error, { type: "error" });
    }
  }, []);

  return (
    <AuthProvider>
      <SimpleSpring location={location}>
        <SignUpHeader
          hideBack={false}
          onBack={() => handleBack()}
          title="Connect to Instagram"
          onClose={() => handleClose()}
        />
        <LoginLayout hideHeader title="Bidddy - Ready to Roll">
          <div className={styles.loginContainer}>
            <ReadyToRoll
              locationPath={locationPath}
              path={LocationTypes.AuctionCreation}
            />
          </div>
        </LoginLayout>
      </SimpleSpring>
    </AuthProvider>
  );
};

export default ReadyToRollPage;
