import React, { useEffect, useState } from "react";
import cn from "classnames";
import Imgix from "react-imgix";
import { useSelector } from "react-redux";
import { navigate } from "gatsby-link";
import styles from "./Onboarding.module.scss";
import BasicTable from "../Table/BasicTable";
import BasicTableHeader from "../Table/BasicTableHeader";
import BasicTableRow from "../Table/BasicTableRow";
import BasicTableBody from "../Table/BasicTableBody";
import { useAppDispatch } from "../../store";
import {
  selectAvailableMarkets,
  selectRequestingUserInfo,
  selectUserInfo,
  selectUserInfoLoading,
} from "../../store/user/selectors";
import { Market } from "../../types/Market";
import {
  getUserInfo,
  getUserInstagramAccounts,
} from "../../store/user/actions";
import Axios from "../../extensions/Axios";
import { baseURL } from "../../utils/axios";
import { event } from "../../lib/ga";
import FullScreenLoading from "../Loading/FullScreenLoading";
import RoundedButton from "../Buttons/RoundedButton";
import {
  getAuctionModalIsEditing,
  getAuction,
} from "../../store/auctionModal/selectors";
import { LocationTypes } from "./ConnectBidddy";
import { AccountTypes } from "../../enums/AccountTypes";
import { Auction } from "../../types/Auction";
import { MethodTypes } from "../../enums/MethodTypes";
import Tooltip from "../Tooltip/Tooltip";
import LoadingSmall from "../Loading/LoadingSmall";

const InfoIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 17C3.80558 17 0 13.1944 0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C16.9948 13.1923 13.1923 16.9948 8.5 17ZM1.7 8.6462C1.74022 12.3873 4.79494 15.3931 8.53616 15.3731C12.2774 15.3529 15.2996 12.3144 15.2996 8.5731C15.2996 4.83183 12.2774 1.79331 8.53616 1.7731C4.79494 1.75309 1.74022 4.75895 1.7 8.5V8.6462ZM10.2 12.75H7.65V9.35H6.8V7.65H9.35V11.05H10.2V12.75ZM9.35 5.95H7.65V4.25H9.35V5.95Z"
      fill="#ABABAB"
    />
  </svg>
);

const InstagramIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3166 21.7255C27.3166 24.8006 24.8528 27.3166 21.8417 27.3166H8.16084C5.15028 27.3166 2.68652 24.8006 2.68652 21.7255V8.27766C2.68652 5.20247 5.15028 2.68652 8.16084 2.68652H21.8417C24.8528 2.68652 27.3166 5.20247 27.3166 8.27766V21.7255ZM22.0924 0H7.90762C3.558 0 0 3.6338 0 8.07601V21.924C0 26.3662 3.558 30 7.90762 30H22.0924C26.442 30 30 26.3662 30 21.924V8.07601C30 3.6338 26.442 0 22.0924 0Z"
      fill="#606060"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9968 19.9714C12.3094 19.9714 10.1309 17.7462 10.1309 15.0016C10.1309 12.2564 12.3094 10.0319 14.9968 10.0312C17.6849 10.0312 19.8635 12.2564 19.8635 15.0016C19.8635 17.7462 17.6849 19.9714 14.9968 19.9714ZM14.9973 7.29785C10.8316 7.29785 7.45508 10.7465 7.45508 15.001C7.45508 19.2556 10.8316 22.7042 14.9973 22.7042C19.1637 22.7042 22.5402 19.2556 22.5402 15.001C22.5402 10.7465 19.163 7.29785 14.9973 7.29785Z"
      fill="#606060"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6838 7.05746C23.6838 7.92958 22.977 8.63641 22.1048 8.63641C21.2327 8.63641 20.5259 7.92958 20.5259 7.05746C20.5259 6.18535 21.2327 5.47852 22.1048 5.47852C22.977 5.47852 23.6838 6.18535 23.6838 7.05746Z"
      fill="#606060"
    />
  </svg>
);

const AddAccountIcon = () => (
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1914 9.53835V4.73722H14.1467V9.53835H13.1914ZM11.2702 7.61364V6.65838H16.0714V7.61364H11.2702Z"
      fill="#505659"
    />
    <path
      d="M11.3392 17.9983V16.7059C11.3392 16.0204 11.0669 15.3629 10.5821 14.8782C10.0974 14.3934 9.43993 14.1211 8.7544 14.1211H3.5848C2.89927 14.1211 2.24181 14.3934 1.75707 14.8782C1.27233 15.3629 1 16.0204 1 16.7059V17.9983"
      stroke="#505659"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.17074 11.5368C7.59828 11.5368 8.75554 10.3795 8.75554 8.95199C8.75554 7.52444 7.59828 6.36719 6.17074 6.36719C4.74319 6.36719 3.58594 7.52444 3.58594 8.95199C3.58594 10.3795 4.74319 11.5368 6.17074 11.5368Z"
      stroke="#505659"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const BidfootReadyToRoll =
  "https://imgix.cosmicjs.com/0e102f50-4809-11ec-86d6-33c0e2e832ec-bidfootrideskateboard-1.png";

const ReadyToRoll = ({ path = null, locationPath = null }) => {
  const dispatch = useAppDispatch();
  const loading = useSelector((state) =>
    selectUserInfoLoading(state)
  ) as boolean;
  const userInfo = useSelector((state) => selectUserInfo(state));
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const availableMarkets = useSelector((state) =>
    selectAvailableMarkets(state)
  ) as Market[];
  const requestingUserInfo = useSelector((state) =>
    selectRequestingUserInfo(state)
  );
  const isEditing = useSelector((state) => getAuctionModalIsEditing(state));
  const [isLoading, setIsLoading] = useState(false);
  const [linkUrl, setLinkUrl] = useState(null);

  useEffect(() => {
    if (!userInfo && !requestingUserInfo) {
      dispatch(getUserInfo());
    }

    dispatch(getUserInstagramAccounts());

    (async () => {
      setIsLoading(true);
      const currentStateObject = {
        type: "CONNECT_INSTAGRAM",
        navigationPath: path,
        location: locationPath,
      };
      // Handle stuff.
      const response = await Axios.get(
        `${baseURL}/link/instagram?format=json&state=${encodeURIComponent(
          JSON.stringify(currentStateObject)
        )}`
      );
      setLinkUrl(response.data.url);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!userInfo && !requestingUserInfo) {
      dispatch(getUserInfo());
    }
  }, [availableMarkets]);

  const checkMarketLinked = (market: Market) => market.facebook_connected;
    // market.account_type !== AccountTypes.UNKNOWN && market.facebook_connected;

  const checkValidAccountType = (market: Market) => {
    // if (
    //   auction.type === MethodTypes.schedule &&
    //   (market.account_type !== AccountTypes.BUSINESS || market.account_type !== AccountTypes.UNKNOWN)
    // ) {
    //   return false;
    // }
    return true;
  };

  const getMarketButtonText = (market: Market) => {
    if (!checkValidAccountType(market) && checkMarketLinked(market)) {
      return "Switch";
    }
    if (!market.facebook_connected) {
      return "";
    }
    if (checkMarketLinked(market) && checkValidAccountType(market)) {
      return "";
    }
    return "Reconnect Account";
  };

  const isAllAccountsUnknown = () => {
    const unknownMarkets = availableMarkets.filter(
      (market) => !checkMarketLinked(market)
    );
    if (unknownMarkets.length === availableMarkets.length) {
      return true;
    }
    return false;
  };

  const signInToInsta = () => {
    if (typeof window !== "undefined" && userInfo) {
      // Handle stuff.
      event({
        action: "instagram_auth_attempt",
        params: {},
      });
      setIsLoading(true);
      window.location.href = linkUrl;
    }
  };

  const handleNext = () => {
    if (availableMarkets.length === 0 || isAllAccountsUnknown()) {
      signInToInsta();
    } else {
      if (locationPath && locationPath === LocationTypes.Account) {
        navigate("/dashboard/account/");
        return;
      }
      if (locationPath && locationPath === "NEW_AUTH_FLOW_CONNECTING_BIDDDY") {
        event({
          action: "instagram_accounts_changed",
          params: {},
        });
        navigate("/dashboard/");
        return;
      }
      if (isEditing) {
        navigate("/dashboard/auctions/edit/choose-account/");
      } else {
        navigate("/dashboard/auctions/create/choose-account/");
      }
    }
  };

  const getAccountType = (market: Market) => {
    if (!checkMarketLinked(market)) {
      return "Unknown";
    }
    switch (market.account_type) {
      case AccountTypes.BUSINESS:
        return "Business Account";
      case AccountTypes.MEDIA_CREATOR:
        return "Creator Account";
      case AccountTypes.UNKNOWN:
        return "Available Account";
      default:
        return "Unknown";
    }
  };

  const getTitle = () => {
    if (availableMarkets.length === 0 || isAllAccountsUnknown()) {
      return "Oops!";
    }
    return "Success!";
  };

  const getHeader = () => {
    if (availableMarkets.length === 0 || isAllAccountsUnknown()) {
      return "Instagram and Bidddy are NOT connected.";
    }
    return "Instagram and Bidddy are now connected.";
  };

  const getNoAccountsTitle = () => {
    if (auction.type === MethodTypes.schedule) {
      return "Switching an Instagram Account to Business Account.";
    }
    return "Switching an Instagram Account to Business or Creator Account.";
  };

  const handleVerifyButtonClick = (market: Market) => {
    if (!checkMarketLinked(market)) {
      // go to connect page
      setIsLoading(true);
      window.location.href = linkUrl;
      return;
    }
    if (!checkValidAccountType(market)) {
      // Not correct account type for auction go to switch page
      navigate("/learn/switching-an-account/");
    }
  };

  const getButtonState = () => {
    if (isLoading) {
      return <LoadingSmall />;
    }
    if (availableMarkets.length === 0 || isAllAccountsUnknown()) {
      return "Try Again";
    }
    return "Next";
  };

  return (
    <>
      <div
        className={cn([
          styles.howItWorksOnboardingContainer,
          styles.switchAccountContainer,
          styles.defaultContainer,
          styles.readyToRollContainer,
        ])}
      >
        {/* <Timeline selectedDot={3} /> */}
        <div className={styles.readyToRollImageContainer}>
          <Imgix
            width={307}
            height={230}
            src={`${BidfootReadyToRoll}?auto=format`}
            htmlAttributes={{
              alt: "Ready To Roll",
              src: `${BidfootReadyToRoll}?auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
          />
        </div>
        <div className={styles.readyToRollCopyContainer}>
          <h3>{getTitle()}</h3>
          <h2>{getHeader()}</h2>
          {(availableMarkets.length === 0 || isAllAccountsUnknown()) && (
            <>
              <p>
                Something has gone wrong? It is either that your Instagram
                account is not set up as a Business or Creator account OR you do
                not have a Facebook page linked to an Instagram account. Please
                see our helpful tips below and try again.
              </p>
              <div className={styles.whatYouNeedSection}>
                <h4>{getNoAccountsTitle()}</h4>
                <p>
                  Need Help?{" "}
                  <a
                    href="/learn/switching-an-account/"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    Click here.
                  </a>
                </p>
              </div>
              <div className={styles.whatYouNeedSection}>
                <h4>Facebook Page Linked to Account</h4>
                <p>
                  Need Help?{" "}
                  <a
                    href="/learn/how-to-link-facebook-page/"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    Click here.
                  </a>
                </p>
              </div>
            </>
          )}
          {availableMarkets.length > 0 && (
            <>
              <BasicTable>
                <BasicTableHeader>
                  <BasicTableRow>
                    <td colSpan={3} className={styles.tableHeader}>
                      Instagram Account/Type
                    </td>
                  </BasicTableRow>
                </BasicTableHeader>
                <BasicTableBody>
                  {availableMarkets &&
                    availableMarkets.length > 0 &&
                    availableMarkets.map((market) => (
                      <BasicTableRow key={`account-${market.name}`}>
                        <td
                          className={cn([
                            styles.defaultAccount,
                            {
                              [styles.activeAccount]:
                                checkValidAccountType(market) &&
                                checkMarketLinked(market),
                              [styles.notActiveAccount]:
                                !checkValidAccountType(market) ||
                                !checkMarketLinked(market),
                            },
                          ])}
                        >
                          <InstagramIcon />
                          <div className="flex flex-col justify-start items-start">
                            <p className={styles.marketName}>@{market.name}</p>
                            <div className="ml-4 flex flex-row">
                              <p
                                className={cn([
                                  styles.marketType,
                                  {
                                    [styles.validAccount]:
                                      checkValidAccountType(market) &&
                                      checkMarketLinked(market),
                                    [styles.notValidAccount]:
                                      !checkValidAccountType(market) ||
                                      !checkMarketLinked(market),
                                  },
                                ])}
                              >
                                {getAccountType(market)}
                              </p>
                              {(!checkValidAccountType(market) ||
                                !checkMarketLinked(market)) && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleVerifyButtonClick(market)
                                  }
                                  className={cn([styles.verifyButton])}
                                >
                                  {getMarketButtonText(market)}
                                </button>
                              )}
                            </div>
                            {!market.facebook_connected && (
                              <p
                                className={cn([
                                  styles.marketType,
                                  styles.notValidAccount,
                                  "ml-4",
                                ])}
                              >
                                Facebook Page is not connected.{" "}
                                <a
                                  className={cn([styles.verifyButton, "ml-0"])}
                                  href="/learn/how-to-link-facebook-page/"
                                  target="_blank"
                                  rel="noopener noreferrer nofollow"
                                >
                                  Learn More.
                                </a>
                              </p>
                            )}
                          </div>
                        </td>
                        {(!checkValidAccountType(market) ||
                          !checkMarketLinked(market)) && (
                          <td>
                            <Tooltip
                              content={
                                <p>
                                  Scheduling auctions REQUIRES a Business
                                  account.
                                </p>
                              }
                            >
                              <InfoIcon />
                            </Tooltip>
                            <div className={styles.toolTipWrapper} />
                          </td>
                        )}
                      </BasicTableRow>
                    ))}
                  {availableMarkets && availableMarkets.length === 0 && (
                    <BasicTableRow>
                      <td id="info">
                        <p className={styles.noAccounts}>
                          No Accounts Available.
                        </p>
                      </td>
                    </BasicTableRow>
                  )}
                </BasicTableBody>
              </BasicTable>
              <div className={styles.accountCheckContainer}>
                <p>
                  Don&apos;t see your account or account unavailable?{" "}
                  <a
                    className={styles.clickHere}
                    href="/learn/why-is-my-account-unavailable/"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >
                    Click Here
                  </a>
                </p>
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => signInToInsta()}
                  onClick={() => signInToInsta()}
                >
                  <AddAccountIcon />
                  Add Account
                </div>
              </div>
            </>
          )}
        </div>

        <RoundedButton
          disabled={isLoading || !linkUrl}
          onClick={() => handleNext()}
        >
          {getButtonState()}
        </RoundedButton>
      </div>
      {loading && <FullScreenLoading />}
    </>
  );
};

export default ReadyToRoll;
